import React from 'react'
import SEOTools from '../../components/SEOTools'
import { DemoSelectorContainer, SelectorLink } from '../../components/DemoSelector'
import CountrySelector from '../../components/CountrySelector'

/**
 * @returns {JSX.Element}
 * @constructor
 */
const IndexPage = () => {
    return (
        <>
            <SEOTools title='Pay with Siru' />
            <section className='full-screen-demo-section'>
                <iframe
                    title='Pay with Siru'
                    className='w-100 border-0 full-screen-demo'
                    src='https://framer.com/embed/Digital-Deposit--4dztYaXMKI2PiW1Ww3Jj/syOveaCsR?highlights=0'
                />
            </section>

            <DemoSelectorContainer rowNumber={1}>
                <SelectorLink to={'/'} name='Pay with Siru' />
                <SelectorLink to={'/wallet'} name='Wallet (check your balance)' />
            </DemoSelectorContainer>

            <CountrySelector />
        </>
    )
}

export default IndexPage
